import "bootstrap";

$(window).scroll(function () {
    if ($(window).scrollTop() >= 1) {
        $("header").addClass("fixed-header");
    } else {
        $("header").removeClass("fixed-header");
    }
});

$(document).ready(function () {
    $(".mobile-menu-icon a").click(function () {
        $(".mobile-menu").toggleClass("menu-open");
        $("body").toggleClass("menu-toggle");
    });

    AOS.init();

    $(
        ".menu-mobile-menu-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-en-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-de-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-fr-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-ch-fr-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-ch-de-container > .nav > .menu-item.menu-item-has-children > a"
    ).click(function (e) {
        if (
            $(this)
                .parent()
                .siblings("li")
                .find("ul.sub-menu:visible").length
        ) {
            $("ul.sub-menu").slideUp("normal");
        }
        $(this)
            .parent()
            .find("ul.sub-menu")
            .slideToggle("normal"); // show the respective one.
    });

    $(
        ".menu-mobile-menu-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-en-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-de-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-fr-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-ch-fr-container > .nav > .menu-item.menu-item-has-children > a, .menu-mobile-menu-ch-de-container > .nav > .menu-item.menu-item-has-children > a"
    ).click(function (e) {
        //console.log($(this).find(".nav_returnlink"));
        if ($(this).hasClass("nav_returnlink")) {
            return true;
        } else {
            $(this).addClass("nav_returnlink");
            return false;
            //e.preventDefault();
        }
    });

    /*$(".menu-mobile-menu-container > .nav > .menu-item.menu-item-has-children > a.nav_returnlink").click(function(e){
      window.location = this.href;
    });*/

    /*Add smooth scrolling to links*/
    $(".down-arrow a").on("click", function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $("html, body").animate(
                {
                    scrollTop: $(hash).offset().top - 138
                },
                800,
                function () {
                    window.location.hash = hash;
                }
            );
        }
    });
    $(".bottom-sticky-bar a, .scroll-to-section").on("click", function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $("html, body").animate(
                {
                    scrollTop: $(hash).offset().top - 190
                },
                800,
                function () {
                    window.location.hash = hash;
                }
            );
        }
    });

    $(".pro-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    });
    $(".testimonial-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $("#info-operating-hours").click(function () {
        alert("Hello");
        $(
            ".info__toggler-contents.store-operating-hours.togglerify-slider"
        ).slideToggle();
    });
});

//FAQ
var coll = document.getElementsByClassName("collapsible-drop");
var i;
var arrowcontainer = document.getElementsByClassName("arrowcontainer");

for (i = 0; i < coll.length; i++) {
    let ac = arrowcontainer[i];
    coll[i].addEventListener("click", function () {
        var content = this.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
            content.style.marginBottom = "0px";
            ac.style.transform = ac.style.transform + "rotateX(180deg)";
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
            content.style.marginBottom = "10px";
            ac.style.transform = ac.style.transform + "rotateX(180deg)";
        }
    });
}

$(".faq-categorie").click(function () {
    $(".faq-categorie").removeClass("active");
    $(".faq-categorie-all").removeClass("active");
    $(this).addClass("active");
    let faqCat = "." + $(this).attr("data-faq");
    $(".faq-section").addClass("d-none");
    $(faqCat).removeClass("d-none");
});

$(".faq-categorie-all").click(function () {
    $(".faq-categorie").removeClass("active");
    $(this).addClass("active");
    $(".faq-section").removeClass("d-none");
});

/**
 * Ajax for mailchimp subscribe
 * Reference mailchimp.php
 */
$("#mailchimp-form").submit(function (e) {
    e.preventDefault();
    console.log("Sending");

    var mailchimpform = $(this);
    $.ajax({
        url: mailchimpform.attr("action"),
        type: "POST",
        dataType: "json",
        data: mailchimpform.serialize(),
        success: function (data) {
            console.log(data["form"]);
            console.log(data["language"]);
            var url = "/" + data["language"] + "/klever-idee-succes";
            if (data["form"] == "Brochure") {
                // url = "/" + data["language"] + "/brochure-succes";
                switch (data["language"]) {
                    case 'nl':
                        url = "/brochure-succes";
                        break;
                    case 'en':
                        url = `/${data["language"]}/brochure-sent-successfully`;
                        break;
                    case 'fr':
                        url = `/${data["language"]}/brochure-envoyee-avec-succes`;
                        break;
                    case 'de':
                        url = `/${data["language"]}/broschuere-erfolgreich-gesendet`;
                        break;
                }
            }

            window.location.href = url;
        },
        error: function (xhr, status) {
            alert("Sorry, there was a problem!");
            console.log(xhr);
            console.log(status);
        }
    });
    return false;
});


/**
 * Fill form hidden field leadsource when comming from add
 * /informatie-aanvraag?utm_source=fb&utm_medium=paid
 */
$.urlParam = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    return results ? results[1] : false;
}

if ($.urlParam('utm_source') && $.urlParam('utm_medium') == 'paid' && $("input[name=leadsource]")[0]) {
    if ($.urlParam('utm_source') == 'fb') {
        $("input[name=leadsource]").val('Facebook')
    }
}
